import React from 'react';

const Grid = ({ children, className }) => {
  return (
    <section className={'grid grid-cols-12 gap-x-7' + (className ? ` ${className}` : '')}>
      {children}
    </section>
  );
};

export default Grid;
